/* Parallax Hero Section */

.hero-section-mobile {
    display: hidden;
    z-index: -1;
}

.hero-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    z-index: 0;
}

.parallax-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    z-index: 0;
}

/* Plot Section */

.plot-section {
    margin-top: 100vh;
    width: 100%;
    height: auto;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.plot-container {
    width: 70%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heading {
    font-size: 6rem;
    color: #f7f7f7;
    font-family: "Titillium Web", sans-serif;
    letter-spacing: 2px;
    font-weight: 900;
    padding: 0;
}

.plot-description {
    font-size: 2rem;
    color: #f7f7f7;
    font-weight: 100;
    line-height: 60px;
    font-family: "Titillium Web", sans-serif;
    letter-spacing: 1.5px;
    margin-top: 2%;
    margin-bottom: 6%;
}

.sub-heading {
    font-size: 4rem;
    color: #f7f7f7;
    font-family: "Titillium Web", sans-serif;
    letter-spacing: 2px;
    font-weight: 900;
    line-height: 60px;
}

/* Flying Items Section */

.items-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
    background-image: url(../../images/items-background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #000000;
    background-blend-mode: screen;
    z-index: 10;
    overflow: hidden;
}

.items-mobile {
    display: none;
}

/* Mission 1 Section */

.countdown-section {
    width: 100%;
    padding: 10% 5%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
    background-color: #110027;
    z-index: 11;
}

.countdown-container {
    /* display: none; */
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}

.col1 {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
}

.col2 {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
}

.p-bold {
    color: #fff;
    font-family: "Titillium Web", sans-serif;
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 60px;
}

.mission-description {
    font-size: 1.5rem;
    color: #f7f7f7;
    font-weight: 100;
    line-height: 45px;
    font-family: "Titillium Web", sans-serif;
    letter-spacing: 1.5px;
    margin-top: 6%;
    margin-bottom: 8%;
}

.time-rats-gif {
    width: 80%;
}

.timer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
}

.timer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin-top: -5%;
}

.timer div:first-child {
    width: 100%;
    height: auto;
    text-align: center;
}

.timer div:last-child {
    border: 1px solid #fff;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    padding: 1% 0% 3% 0%;
    text-align: center;
    width: 60%;
}

section.digits {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2%;
}

section p:first-child,
.timer div:last-child span {
    font-size: 3rem;
    text-align: center;
    color: #f7f7f7;
}

.timer div:last-child span {
    font-size: 2rem;
    margin-bottom: 28px;
}

.btn2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 55px;
    background-color: transparent;
    color: #f7f7f7;
    padding: 8px 20px;
    border: 1px solid #f7f7f7;
    transition: all 0.3s ease-out;
    font-size: 1.5rem;
    font-weight: 700;
    font-family: "Titillium Web", sans-serif;
    cursor: pointer;
}

.btn2:hover {
    background-color: #f7f7f7;
    color: #110027;
}

/* MEDIA QUERIES */

@media screen and (max-width: 1400px) {
    .logo-animate {
        margin-top: -20px;
        padding-right: 6%;
    }
}

@media screen and (max-width: 960px) {
    body {
        background-image: url(../../images/hero-bg-mobile.gif);
        // background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        -webkit-background-size: contain;
        -moz-background-size: contain;
        -o-background-size: contain;
        background-attachment: scroll;
        background-blend-mode: overlay;
    }

    .parallax-section {
        display: none;
    }

    .hero-section-mobile {
        height: 100%;
        background-color: transparent;
    }

    .plot-section {
        margin-top: 0vh;
        background: linear-gradient(0deg, rgba(17, 0, 39, 1) 35%, rgba(17, 0, 39, 0) 50%);
    }

    .plot-container {
        margin-top: 31vh;
        width: 80%;
    }

    .heading {
        font-size: 4rem;
    }

    .plot-description {
        font-size: 2rem;
        line-height: 40px;
    }

    .sub-heading {
        font-size: 3rem;
    }

    .items-container {
        display: none;
    }

    .items-mobile {
        margin-top: -350px;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url(../../images/items-bg-mobile-landscape.jpg);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #110027;
    }

    .items {
        display: none;
    }

    .items2 {
        width: 100%;
        height: auto;
        z-index: 11;
    }

    .p-bold-items {
        position: absolute;
        color: #f7f7f7;
        text-shadow: 0 0 20px aqua;
        margin-top: 6%;
        font-size: 5rem;
        font-weight: 900;
        line-height: 70px;
        z-index: 11;
        filter: drop-shadow(0px 4px 4px #000000);
    }

    .countdown-section {
        width: 100%;
        margin-top: -15%;
        padding: 0 5% 10% 5%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 5;
        background-color: #110027;
    }

    .countdown-container {
        flex-direction: column;
    }

    .col1 {
        width: 100%;
    }

    .col2 {
        width: 100%;
        padding: 5% 0% 5% 0%;
    }

    .mission-description {
        font-size: 2rem;
    }

    .timer-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
    }

    .timer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 75%;
        height: auto;
        margin-top: -3.8%;
    }

    .timer div:first-child {
        width: 100%;
        height: auto;
        text-align: center;
    }

    .timer div:last-child {
        width: 80%;
        padding: 2% 0 5% 0;
    }

    .logo-time-rats-inner {
        width: 200px;
        position: fixed;
        top: 17px;
        left: 150px;
        z-index: 2000;
    }
}

@media screen and (max-width: 480px) {
    iframe {
        width: 95%;
    }

    .heading {
        font-size: 3rem;
    }

    .plot-description {
        font-size: 1.5rem;
        line-height: 40px;
    }

    .sub-heading {
        font-size: 2.5rem !important;
        line-height: 40px;
    }

    .items-mobile {
        margin-top: -150px;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url(../../images/items-bg-mobile.jpg);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .items2 {
        display: none;
    }

    .items {
        display: block;
        width: 100%;
        height: auto;
        z-index: 11;
    }

    .p-bold-items {
        position: absolute;
        color: #f7f7f7;
        text-shadow: 0 0 20px aqua;
        margin-top: 0%;
        font-size: 3rem;
        font-weight: 900;
        line-height: 40px;
        z-index: 11;
        filter: drop-shadow(0px 4px 4px #000000);
    }

    .countdown-container {
        width: 100%;
    }

    .col1 {
        width: 100%;
    }

    .col2 {
        width: 100%;
        padding: 15% 5%;
    }

    .mission-description {
        font-size: 1.5rem;
        line-height: 40px;
    }

    .timer-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
    }

    .timer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 85%;
        height: auto;
        margin-top: 0;
    }

    .timer div:first-child {
        width: 100%;
        height: auto;
        text-align: center;
    }

    .timer div:last-child {
        margin: 0 2em;
        padding: 2% 0 8% 1%;
    }

    .time-rats-gif {
        width: 85%;
    }
}

.loader {
    width: 80vh;
    height: auto;
}

.collectAndCombineSection {
    padding: 15% 8% 0% 8%;
    background-color: #000000;
    z-index: 11;
    height: auto;

    a{
        text-decoration: none;
        text-shadow: none;
    }

    .headingAndTextSection {
        text-align: start;
        padding-right: 50px;

        .heading {
            font-size: 36px;
            color: #fff;
            font-weight: 400;
            margin-bottom: 20px;
            font-family: 'Gugi', cursive;
        }

        .descrption {
            font-size: 22px;
            color: #fff;
            font-weight: 500;

            span {
                font-weight: 500;
                margin-top: 15px;
                display: block;
            }
        }

        .buttonSection {
            margin-top: 20px;

            .removeWidth{
                display: inline-block;
            }

            .animationButton{
                position: relative;

                &:hover{
                    .after{
                        display: block;
                    }
                }

                &.second{
                    display: block;
                    margin: 20px 0px;
                }

                img {
                    height: 50px;
                    margin-right: -8px;

                    &.before, &.after{
                        &.looksrareIcon {
                            height: 35px;
                            margin: 0px;
                        }
                        &.siteIcon{
                            height: 40px;
                        }
                    }

                    &.before{
                        display: block;
                    }
                    &.after{
                        display: none;
                    }
                }
            
                .mas{
                    width: 265px;
                    height: 42px;
                    position: absolute;
                    padding: 0px 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #ffffff;
                    font-size: 18px;
                    text-align: center;
                    background-color: #ff0000;
                    overflow: hidden;
                    border-radius: 5px;
                }
                .btn-mask {
                    width: 265px;
                    height: 42px;
                    -webkit-mask: url(../../images/buttonAnimation/greenIamge.png);
                     mask:url(../../images/buttonAnimation/greenIamge.png); 
                    -webkit-mask-size: 7100% 100%;
                    mask-size: 7100% 100%;
                    border: none;
                    font-size: 18px;
                    background-color: #ffffff;
                    border-radius: 5px;
                    padding: 0px 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                    -webkit-animation: ani2 .7s steps(70) forwards;
                    animation: ani2 .7s steps(70) forwards;
                }   
                .btn-mask:hover {
                    -webkit-animation: ani .7s steps(70) forwards;
                    animation: ani .7s steps(70) forwards;
                    .before{
                        display: none;
                    }
                }
            }
        }
    }

    .imageSection {
        img {
            height: 500px;
            width: 80%;
            margin: auto;
            object-fit: contain;
        }
    }
    &.entertainEducate{
        .headingAndTextSection{
            .buttonSection{
                .animationButton{
                    &:first-child{
                        display: block;
                        margin-bottom: 20px;
                    }
                    .mas, .btn-mask{
                        justify-content: center;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .collectAndCombineSection {
        padding: 20px 40px;

        .headingAndTextSection {
            padding: 0px;

            .heading, .descrption{
                text-align: center;
            }

            .buttonSection {
                .removeWidth{
                    display: inline-block;
                    margin: auto;
                    width: 100%;
                }
                .animationButton{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;

                    &.second{
                        display: flex;
                    }
                }
            }
        }

        .imageSection {
            margin-top: 50px;

            img {
                height: 300px;
                width: 100%;
            }
        }

        &.entertainEducate{
            .headingAndTextSection{
                .buttonSection{
                    .animationButton{
                        &:first-child{
                            display: flex;
                            margin-bottom: 20px;
                        }
                        .mas, .btn-mask{
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}

@-webkit-keyframes ani {
    0% {
        -webkit-mask-position: 0 0;
        mask-position: 0 0;
    }

    to {
        -webkit-mask-position: 100% 0;
        mask-position: 100% 0;
    }
}

@keyframes ani {
    0% {
        -webkit-mask-position: 0 0;
        mask-position: 0 0;
    }

    to {
        -webkit-mask-position: 100% 0;
        mask-position: 100% 0;
    }
}

@-webkit-keyframes ani2 {
    0% {
        -webkit-mask-position: 100% 0;
        mask-position: 100% 0;
    }

    to {
        -webkit-mask-position: 0 0;
        mask-position: 0 0;
    }
}

@keyframes ani2 {
    0% {
        -webkit-mask-position: 100% 0;
        mask-position: 100% 0;
    }

    to {
        -webkit-mask-position: 0 0;
        mask-position: 0 0;
    }
}
