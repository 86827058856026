.clicker-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
}

.conversion-container{
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 0;
}

.number-comversion-container{
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}

.mint-title{
    margin: 0 auto;
    color: #110027;
    font-family: 'Digital dream', sans-serif;
    font-size: 1.2rem;
    padding-bottom: 10px;
    margin-top: 0px;
}

.clicker-digit{
    width: 55%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 45px;
    color: #f7f7f7;
    background-color: #00648a;
    font-size: 1.2rem;
    border: 1px solid #000000;
    margin: 0;
    font-family: 'Digital dream', sans-serif;
}

.total-container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: right;
    margin-top: 1%;
}

.total{
    width: 88%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 45px;
    color: #f7f7f7;
    background-color: #00648a;
    font-size: 1.2rem;
    border: 1px solid #000000;
    font-family: 'Digital dream', sans-serif;
}

.clicker-btn {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 5%;
    height: 55px;
    background-color: transparent;
    color: #f7f7f7;
    padding: 0px 18px;
    border: none;
    transition: all 0.3s ease-out;
    font-size: 1.2rem;
    font-weight: 700;
    font-family: 'Digital dream', sans-serif;
    cursor: pointer;
  }

  #plus{
      background-size: cover;
      background-position: center;
      background-image: url(../../images/button-up.png);
  }

  #minus{
    background-size: cover;
    background-position: center;
    background-image: url(../../images/button-down.png);
}

  .mint-btn-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: auto;
  }
  
  .mint-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 55px;
    background-image: url(../../images/red-button.png);
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    background-color: transparent;
    color: #f7f7f7;
    border: none;
    transition: all 0.3s ease-out;
    font-size: .8rem;
    font-weight: 700;
    font-family: 'Digital dream', sans-serif;
    cursor: pointer;
    margin: 0 auto;
  }
  .mint-btn:disabled{
    pointer-events: none;
    background: grey !important;
    border-radius: 3px;
    width: 85%;
  }

  
/* #five{
    background-color:turquoise;
}

#ten{
    background-color: aqua;  
}

#twenty{
    background-color:deepskyblue;
} */
.wrongNetworkErrorMessage{
    margin-top: 10px;
    font-size: 16px;
    color: rgba(255,0,0,.7);
}
.progressBar{
    margin-top: 15px;
    font-size: 24px;
}
@media screen and (max-width: 480px) {
    .mint-btn{
        height: 45px;
        font-size: .65rem;
    }

    .clicker-digit{
        height: 35px;
    }

    .clicker-btn{
        height: 45px;
    }

    .total{
        font-size: 1rem;
        height: 35px;
    }
    .wrongNetworkErrorMessage{
        margin-top: 5px;
        font-size: 12px;
    }
    .progressBar{
        margin-top: 5px;
        font-size: 12px;
    }
    .mint-title{
        font-size: 14px;
    }
}

