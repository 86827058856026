.footer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    z-index: 900;
    padding: 10%;
}

.scrumble-description {
    font-size: 4rem;
    color: #f7f7f7;
    text-shadow: 0 0 20px aqua;
    font-weight: 100;
    line-height: 45px;
    font-family: "Titillium Web", sans-serif;
    letter-spacing: 1.5px;
}

.ratImageSection {
    margin: 200px 0px 50px 0px;
    color: #ffffff;
    width: 100%;

    .animationImage {
        position: relative;
        width: fit-content;

        .mas {
            position: absolute;
            height: 300px;
            width: 93%;
            //background: url(../../images/footerImages/merchStore.png);
            background: url(../../images/footerImages/timeRatLogo.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        .btn-mask {
            -webkit-mask: url(../../images/buttonAnimation/greenIamge.png);
            mask: url(../../images/buttonAnimation/greenIamge.png);
            -webkit-mask-size: 7100% 100%;
            mask-size: 7100% 100%;
            -webkit-animation: ani2 0.7s steps(70) forwards;
            animation: ani2 0.7s steps(70) forwards;
            height: 300px;
            width: 78%;
            background: url(../../images/footerImages/merchStore.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-color: #000000;
            border: none;
            cursor: pointer;

            &:hover {
                -webkit-animation: ani 0.7s steps(70) forwards;
                animation: ani 0.7s steps(70) forwards;
            }

            &.rat2 {
                background: url(../../images/footerImages/ourStory.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }

            &.rat3 {
                background: url(../../images/footerImages/ratLocker.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }
}

.footerSection {
    z-index: 11;
    background: #171717;
    width: 100%;
    padding: 40px 0px;

    .footerlogo {
        img {
            height: 60px;
        }
    }
    .footer-icons {
        padding: 20px 0px;
        width: 300px;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;

        .animationButton {
            position: relative;

            .mas {
                border-radius: 50px;
                width: 50px;
                height: 50px;
                position: absolute;
                padding: 0px 20px;
                background: url(../../images/twitter-icon-white.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 80%;
                background-color: #ff0000;
                border: 1px solid #f7f7f7;

                &.discord {
                    background: url(../../images/discord-icon-white.png);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 80%;
                    background-color: red;
                }

                &.medium {
                    background: url(../../images/medium-icon-white.png);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 80%;
                    background-color: red;
                }

                &.instagram {
                    background: url(../../images/instagram-icon-white.png);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 80%;
                    background-color: red;
                }
            }
            .btn-mask {
                border-radius: 50px;
                width: 50px;
                height: 50px;
                -webkit-mask: url(../../images/buttonAnimation/greenIamge.png);
                mask: url(../../images/buttonAnimation/greenIamge.png);
                -webkit-mask-size: 7100% 100%;
                mask-size: 7100% 100%;
                border: 1px solid #f7f7f7;
                background: url(../../images/twitter-icon-white.png);
                background-color: #171717;
                padding: 0px 20px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 80%;
                cursor: pointer;
                -webkit-animation: ani2 0.7s steps(70) forwards;
                animation: ani2 0.7s steps(70) forwards;

                &:hover {
                    -webkit-animation: ani 0.7s steps(70) forwards;
                    animation: ani 0.7s steps(70) forwards;
                }

                &.discord {
                    background: url(../../images/discord-icon-white.png);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 80%;
                    background-color: #171717;
                }

                &.medium {
                    background: url(../../images/medium-icon-white.png);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 80%;
                    background-color: #171717;
                }

                &.instagram {
                    background: url(../../images/instagram-icon-white.png);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 80%;
                    background-color: #171717;
                }
            }
        }
    }

    .contractButtonSection {
        padding: 10px 0px;
        .contractButton {
            height: 55px;
            width: 300px;
            margin: auto;
            font-size: 18px;
            color: #fff;
            background-color: transparent;
            border: 1px solid #fff;
            padding: 0px 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer !important;
            box-shadow: inset 0 0 0 0 #ffffff;

            &:hover {
                box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
                animation: pulse-white 2s infinite;
                transition: 1s;
                border: 1px solid #fff;
                background: #fff;
                color: #000;
            }
        }
    }

    .termsAndServices {
        a {
            text-decoration: none;
        }
        p {
            font-size: 14px;
            font-weight: 200;
            cursor: pointer;
            width: fit-content;
            margin: auto;
            &:hover {
                opacity: 0.8;
            }
        }
    }

    .allRightReservedSection {
        margin-top: 10px;
        font-size: 14px;
        color: #ffffff;
        font-weight: 200;
    }
}

@media screen and (max-width: 960px) {
    .footer {
        background-color: #000000;
        height: auto;
        padding: 2.5% 0;
    }

    .footer-container {
        padding: 10% 15%;
    }

    .footerSection {
        .footer-icons {
            width: 100%;
            padding: 0px;
            margin: 20px 0px;
        }

        .footerlogo {
            img {
                height: 70px;
                width: 100%;
            }
        }

        .contractButtonSection {
            padding: 20px 0px;
        }
    }
}

@media screen and (max-width: 600px) {
    .footer-icons {
        width: 100%;
    }

    .p-bold {
        font-size: 2rem;
        line-height: 2.5rem;
    }

    .scrumble-description {
        font-size: 2.5rem;
        min-height: 150px;
    }

    .ratImageSection {
        margin: 0px 0px 50px 0px;
    }
    .footerSection {
        .footerlogo {
            img {
                height: 70px;
                width: 100%;
            }
        }

        .footer-icons {
            width: 100%;
            padding: 0px;
            margin: 20px 0px;
        }
        .contractButtonSection {
            padding: 20px 0px;
        }
    }
}

@-webkit-keyframes ani {
    0% {
        -webkit-mask-position: 0 0;
        mask-position: 0 0;
    }

    to {
        -webkit-mask-position: 100% 0;
        mask-position: 100% 0;
    }
}

@keyframes ani {
    0% {
        -webkit-mask-position: 0 0;
        mask-position: 0 0;
    }

    to {
        -webkit-mask-position: 100% 0;
        mask-position: 100% 0;
    }
}

@-webkit-keyframes ani2 {
    0% {
        -webkit-mask-position: 100% 0;
        mask-position: 100% 0;
    }

    to {
        -webkit-mask-position: 0 0;
        mask-position: 0 0;
    }
}

@keyframes ani2 {
    0% {
        -webkit-mask-position: 100% 0;
        mask-position: 100% 0;
    }

    to {
        -webkit-mask-position: 0 0;
        mask-position: 0 0;
    }
}
