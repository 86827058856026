.termsAndconditionSection {
    padding: 100px 10% 10% 10%;
    background: #000;
    z-index: 9;
    width: 100%;
    h1 {
        font-size: 80px;
        color: #000000 !important;
        -webkit-text-stroke: 2px #ffbf00;
    }
    h2,
    p {
        margin-top: 20px;
        color: #fff;
        text-align: start;
    }
    P {
        font-weight: 400;
        span {
            margin-bottom: 15px;
            display: block;
            font-weight: 400;
        }
    }
}
@media only screen and (max-width: 600px) {
    .termsAndconditionSection{
        padding: 80px 10px;
        h1{
            font-size: 46px; 
        }
        h2{
            font-size: 20px;
        }
        p{
            font-size: 14px;
        }
    }
}
