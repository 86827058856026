.navbar {
    background: transparent;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
  }

  .logo-time-rats{
    width: 200px;
    height: auto;
    margin-top: 18px;
    cursor: pointer;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: flex;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
    margin-top: 20px;
    align-items:center;
  }
  .nav-menu .nav-item .f-icon-twitter,.nav-menu .nav-item .f-icon-discord{
    height: 38px;
    width: 38px;
  }
  
  .nav-item {
    height: 80px;
    cursor: pointer;
    font-size: .5rem;
    display: flex;
    align-items: center;
  }
  
  .nav-links, .nav-item a{
    color: #f7f7f7;
    text-shadow: none;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    height: 100%;
    font-family: 'Gugi', cursive;
  }
  
  .nav-links:hover, .nav-item a:hover{
    border-bottom: 4px solid #f7f7f7;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #f7f7f7;
  }
  
  .nav-links-mobile {
    /* display: none; */
    display: flex;
    color: #f7f7f7;
    text-shadow: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2% 20px;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 100%;
    text-decoration: none;
    font-size: 1.2rem;
    background-color: transparent;
    color: #f7f7f7;
    margin-top: 18px;
    border: 1px solid #f7f7f7;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #f7f7f7;
    color: #110027;
    transition: 250ms;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {

    .navbar-logo{
      z-index: 1000;
    }

    .menu-icon{
      z-index: 1000;
    }

    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      margin-top: 0;
    }
  
    .nav-menu.active {
      background: #110027;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
      font-family: 'Gugi', cursive;
    }
  
    .nav-links:hover {
      background-color: #f7f7f7;
      color: #110027;
      border-radius: 0;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #f7f7f7;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      width: 60%;
      margin-top: 20px;
    }
  }