.nftSection {
    background: #000;
    padding: 0% 10%;
    padding-top: 80px;

    .heading {
        padding: 20px 0px;
        font-size: 80px;
        color: #000000 !important;
        -webkit-text-stroke: 2px #FFBF00;
    }

    .row {
        background: #110027;
        padding: 0px;

        .col-4,
        .col-12 {
            padding: 0px;
        }

        .textSextion {
            padding: 20px 0px 0px 40px;

            .logoSection {
                text-align: start;
                margin-left: -20px;

                img {
                    width: 70%;
                }
            }

            h1 {
                color: #fff;
                text-align: start;
            }

            p {
                padding: 20px 0px;
                font-size: 22px;
                text-align: start;
                font-weight: 400;
                span{
                    display: block;
                    font-weight: 400;
                }
            }

            .buttonSection {
                display: flex;
                width: fit-content;
                align-items: center;

                .animationButton{
                    position: relative;
                    display: block;
                    margin-right: 10px;
                
                    .mas{
                        padding: 10px 20px;
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #ffffff;
                        font-size: 14px;
                        text-align: center;
                        background-color: #ff0000;
                        overflow: hidden;
                        border-radius: 5px;
                    }
                    .btn-mask {
                        padding: 10px 20px;
                        -webkit-mask: url(../../images/buttonAnimation/greenIamge.png);
                         mask:url(../../images/buttonAnimation/greenIamge.png); 
                        -webkit-mask-size: 7100% 100%;
                        mask-size: 7100% 100%;
                        border: none;
                        font-size: 14px;
                        background-color: #ffffff;
                        border-radius: 5px;

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        -webkit-animation: ani2 .7s steps(70) forwards;
                        animation: ani2 .7s steps(70) forwards;
                    }   
                    .btn-mask:hover {
                        -webkit-animation: ani .7s steps(70) forwards;
                        animation: ani .7s steps(70) forwards;
                        .before{
                            display: none;
                        }
                    }
                }

                .otherSiteButtonSection {
                    display: flex;
                    align-items: center;

                    img {
                        height: 37px;
                        margin: 5px;
                        cursor: pointer;

                        &.looksrareLogo {
                            border-radius: 50px;
                            height: 40px;
                        }

                        &:hover{
                                opacity: 0.8;
                        }
                    }
                }
            }
        }

        .imageSection {
            margin-top: -40px;

            img {
                height: 100%;
                width: 100%;
            }
        }

        &.oldWorld {
            margin: 300px 0px;
            background: #5A3300;
        }

        &.honorary {
            background: #FFBF00;

            .textSextion {
                h1 {
                    color: #000;
                }

                p {
                    color: #000;
                }

                .otherSiteLogoSection {
                    img {
                        height: 40px;
                    }
                }
            }

        }
    }

    .list_of_images {
        overflow: hidden;
    }

    .marqueeLeft {
        display: flex;
        width: 200px;
        position: relative;
        height: 200px;
        animation: scrollLeft 30s linear infinite;
        padding: 0px 3px;

        // overflow: scroll;
        img {
            margin: 0px 1px;
        }
    }

    @keyframes scrollLeft {
        0% {
            left: 0px;
        }

        100% {
            left: -100%;
        }
    }

    @keyframes mobileScrollLeft {
        0% {
            left: 0px;
        }

        100% {
            left: -300%;
        }
    }

    .marqueeRight {
        display: flex;
        width: 100%;
        position: relative;
        height: 200px;
        animation: scrollRight 20s linear infinite;
        padding: 3px;
    }

    @keyframes scrollRight {
        0% {
            left: -100%;
        }

        100% {
            left: 0px;
        }
    }

    @keyframes mobileScrollRight {
        0% {
            left: -300%;
        }

        100% {
            left: 0px;
        }
    }
}

@media only screen and (max-width: 600px) {
    .nftSection {
        padding: 80px 0px;

        .heading {
            font-size: 46px;
        }

        .row {
            .textSextion {
                padding: 20px;

                h1{
                    font-size: 20px;
                }
                p{
                    font-size: 18px;
                    span{
                        display: inline;
                    }
                }

                .buttonSection {
                    .mintButoon {
                        padding: 12px;
                        font-size: 14px;
                        margin-right: 10px;
                    }
                }
            }

            &.oldWorld {
                margin: 100px 0px;
            }

            .imageSection {
                margin: 20px 0px 0px 0px;

                img {
                    height: 300px;
                    object-fit: contain;
                }
            }
        }
        .marqueeLeft{
        height: 150px;
        }
    }
}
@media only screen and (max-width: 768px) {
    .nftSection {
        padding: 80px 0px;

        .heading {
            font-size: 46px;
        }

        .row {
            .textSextion {
                padding: 20px;

                h1{
                    font-size: 20px;
                }
                p{
                    font-size: 18px;
                    span{
                        display: inline;
                    }
                }

                .buttonSection {
                    .mintButoon {
                        padding: 12px;
                        font-size: 14px;
                        margin-right: 10px;
                    }
                }
            }

            &.oldWorld {
                margin: 100px 0px;
            }

            .imageSection {
                margin: 20px 0px 0px 0px;

                img {
                    height: 300px;
                    object-fit: contain;
                }
            }
        }
        .marqueeLeft{
        height: 150px;
        width: 150px;
        }
    }
}