html,
body {
  overscroll-behavior: none;
}

body {
  font-family: 'Titillium Web', sans-serif;
  text-align: center;
  background: #555;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 700;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}
.row{
  z-index: 11 !important;
}

.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}

[class*="col-"] {
  float: left;
  padding: 15px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
}

p {
  color: #fff;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 700;
}

a {
  color: #f7f7f7;
        text-shadow: 0 0 20px aqua;
  font-size: 1.5rem;;
}

.alert{
  position: fixed;
  top:100px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999999999999 !important;
  padding: 15px;
}
.alert.error{
  color: #721c24;
  background-color: #f8d7da;
}
.alert.success{
  color: #155724;
  background-color: #d4edda;
}
.alert.success a{
  color: #155724;
  background-color: #d4edda;
  font-size: 16px;
  box-shadow: none;
  text-shadow:none;
  text-decoration: underline;
}
.passwordSection{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.passwordSection h3{
  padding-right: 10px;
  font-size: 20px;
  text-transform: uppercase;
}
.passwordSection .passwordFild{
  border-radius: 3px;
  margin-top: 20px;
  border:1px solid rgba(0,0,0,.5);
  height: 40px;
  padding: 0px 5px;
  text-align: center;
  width: 100%;
}
.passwordSection .enterButton{
  margin-top: 20px;
  padding: 8px 40px;
  background-color: #110027;
  color: #ffffff;
  border-radius: 3px;
  border: 1px solid #110027;
  transition:.5s;
}
.passwordSection .enterButton:hover{
  cursor: pointer;
  background-color: #ffffff;
  color: #110027;
  border: 1px solid #110027;
}

@keyframes pulse-white {
  0% {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }
  
  70% {
  box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }
  
  100% {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}