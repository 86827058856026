.mint-container{
    /* display: none; */
    width: 100%;
    height: 100vh;
    padding: 100px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #110027;
}

.mint{
    height: auto;
    padding: 0;
}

.mint-wrapper{
    width: 100%;
    height: auto;
}
.joinUs{
    color: #ffffff;
    font-size: 26px;
    margin-bottom: 20px;
}
.saleStart{
    margin-top: 20px;
    color: #ffffff;
    font-size: 26px;
}
.discordText{
    color: #ffffff;
    font-size: 18px;
}
.discordText a{
    box-shadow: none;
    text-shadow:none;
    font-size: 18px;
}
.discordText a:hover{
    opacity: .7;
}

.mint-total{
    margin: 0 auto;
    color: #110027;
    font-family: 'Digital dream', sans-serif;
    font-size: 1.2rem;
    margin-top: 0px;
}

.mint-conversion{
    width: auto;
    height: 300px;
    padding: 50px;
    background-image: url(../../images/mint-panel.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
}

input[type=number] {
width: 37.5%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 55px;
color: #f7f7f7;
font-size: 2rem;
border: 1px solid #000000;
text-align: center;
}

  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.time-machine-full-body{
    width: 60vh;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.time-machine-full-body img{
    width: 80%
}

@media screen and (max-width: 960px){
    .mint-container{
        flex-direction: column-reverse;
        height: auto;
    }

    .time-machine-full-body{
        display: none;
    }
    .joinUs{
        font-size: 24px;
    }
    .saleStart{
        font-size: 24px;
    }
    .discordText{
        font-size: 16px;
    }
    .discordText a{
        font-size: 16px;
    }
    

}

@media screen and (max-width: 480px) {
    
    .mint{
        margin-top: 20px;
        width: 100%;
    }

    .mint-conversion{
        width: 100%;
        height: 200px;
        padding: 25px;
    }
    .joinUs{
        font-size: 14px;
    }
    .saleStart{
        font-size: 14px;
    }
    .discordText{
        font-size: 14px;
    }
    .discordText a{
        font-size: 12px;
    }
}



